'use client';

import React, { useState, useEffect } from 'react';
import { GrowthBookContext } from '@growthbook/growthbook-react';
import { getSearchParam } from '../utils/dom/locationHelpers';
import loadFeature from './loadFeature';

export type ExperimentHelperProps = {
  feature: string;
};

const useExperiment = ({ feature }: ExperimentHelperProps): string => {
  const [variant, setVariant] = useState('');
  const { growthbook } = React.useContext(GrowthBookContext);
  const forcedExperiment = getSearchParam('forceExperiment');
  const growthbookDisabled = getSearchParam('growthbookDisabled') === 'true';
  let featureData: any = null;
  if (!growthbookDisabled) {
    featureData = loadFeature(feature);
  }
  const experimentKey = String(featureData?.experimentResult?.key);

  useEffect(() => {
    if (featureData?.on && experimentKey) {
      setVariant(experimentKey);
      if (forcedExperiment) {
        const key = forcedExperiment.split('.')[0];
        const forceVariant = forcedExperiment.split('.')[1];
        if (key === feature && experimentKey !== forceVariant) {
          growthbook.forceVariation(key, Number(forceVariant));
          setVariant(forceVariant);
        }
      }
    }
  }, [featureData?.on, experimentKey]);

  return variant;
};

export default useExperiment;
