'use client';

import { useCallback, useEffect, useState } from 'react';
import { MenuLink } from '@/ts';
import { useHeaderStore } from '@/store/header/header-providers';
import { preventFocus, resetFocusing } from '@/helpers/navCurtain/curtainHelpers';
import { Territory } from '@/ts/interfaces/territorySwitcher';
import { getFontOnlyForPublication } from '@/helpers/utils/fonts';
import { joinClassNames } from '@/helpers/utils/classNames';
import PrimaryMenuHeading from '@/components/molecules/PrimaryMenuHeading/PrimaryMenuHeading';
import SecondaryNavigationHeader from '@/components/molecules/SecondaryNavigation/SecondaryNavigation';
import TerritorySwitcher from '@/components/molecules/TerritorySwitcher/TerritorySwitcher';
import styles from './styles/NavCurtain.module.scss';

export interface NavCurtainProps {
  onClick?: () => void | undefined;
  publication?: string;
  isOpen?: boolean;
  showInnerClose?: boolean;
  navData?: {
    'Header-Primary-4'?: MenuLink[];
    'Header-Primary-Curtain'?: { [key: string]: MenuLink[] };
    'Header-Secondary'?: MenuLink[];
  };
  secondaryNavRef?: any;
  secondaryNavigationHeaderEnabled?: boolean;
  territorySwitcher?: {
    territorySwitcherEnabled?: boolean;
    territories?: Territory[];
  };
}

export const calcCurtainHeight = (callback: any) => {
  const scrollPosition = window.scrollY || 0;
  const vh = window.innerHeight;
  const ad = document.querySelector(`[data-testid="commercial-above-header-box-1"]`) as Element;
  const adHeight = parseInt(getComputedStyle(ad).height, 10);
  const scrollDiff = adHeight >= scrollPosition ? adHeight - scrollPosition : 0;
  const newHeight = vh - 56 - scrollDiff;
  callback(newHeight);
};

const tabFocusing = (() => {
  const mainElements: Element[] = [];
  const mainValues: any[] = [];
  const footerElements: Element[] = [];
  const footerValues: any[] = [];

  return {
    prevent: () => {
      preventFocus(mainElements, mainValues, '#main-content');
      preventFocus(footerElements, footerValues, 'footer');
      document.body.style.overflow = 'hidden';
    },
    reset: async () => {
      resetFocusing(mainElements, mainValues);
      resetFocusing(footerElements, footerValues);
      document.body.style.overflow = 'auto';
    },
  };
})();

const NavCurtain: React.FC<NavCurtainProps> = ({
  navData = undefined,
  publication,
  secondaryNavigationHeaderEnabled,
  territorySwitcher = {},
}) => {
  const headerStore = useHeaderStore((store) => store);
  const { state } = headerStore;
  const { burgerOpen } = state;

  const primary = navData?.['Header-Primary-Curtain']
    ? Object.values(navData?.['Header-Primary-Curtain'] as { [key: string]: any[] }).flat()
    : [];

  const [curtainHeight, setCurtainHeight] = useState(Number(0));

  const handleFocus = useCallback(() => {
    setTimeout(() => {
      const firstElem = document.querySelector(`header > button`);
      (firstElem as HTMLAnchorElement).focus();
    }, 0);
  }, []);

  useEffect(() => {
    if (burgerOpen) {
      tabFocusing.prevent();
      handleFocus();
      calcCurtainHeight(setCurtainHeight);
    } else {
      tabFocusing.reset();
    }
  }, [burgerOpen, handleFocus]);

  if (!burgerOpen) return null;

  return (
    <nav
      className={`${styles['nav-curtain']} .tab-focus-all-elems`}
      id="mobile-nav"
      data-testid="nav-curtain"
      style={{ height: curtainHeight }}
    >
      {territorySwitcher.territorySwitcherEnabled && territorySwitcher.territories && (
        <TerritorySwitcher territories={territorySwitcher.territories} layout="column" />
      )}
      <ul
        className={joinClassNames(
          styles['nav-list'],
          publication === 'mirror' && getFontOnlyForPublication(publication)
        )}
        role="menu"
      >
        {primary.map((section: MenuLink, index: number) => (
          <PrimaryMenuHeading item={section} key={index} itemKey={index} />
        ))}
      </ul>
      {secondaryNavigationHeaderEnabled && (
        <SecondaryNavigationHeader isNavCurtain={true} items={navData?.['Header-Secondary']} />
      )}
    </nav>
  );
};

NavCurtain.displayName = 'NavCurtain';

export default NavCurtain;
