'use client';

import { useCallback } from 'react';

import useAvatar from '@/hooks/useAvatar/useAvatar';
import type { LoginRadiusEnv, UserBasicInfo, UserOnly } from '@/ts';
import avatarStyles from '@/components/atoms/Avatar/styles/Avatar.module.scss';
import Avatar from '@/components/atoms/Avatar/Avatar';
import AvatarDropdown from '@/components/molecules/AvatarDropdown/AvatarDropdown';
import AuthModal from '@/components/organisms/AuthModal/AuthModal';

interface AvatarHooks {
  avatarClickHandler: (origin?: string) => void;
  isLoggedIn: boolean;
  user: UserOnly;
  isMenuOpened: boolean;
}

interface AvatarBlockProps {
  isAuthFlowEnabled: boolean;
  $publicationName: string;
  loginRadiusEnv?: LoginRadiusEnv;
  publication: string;
}

const AvatarBlock = ({ isAuthFlowEnabled, $publicationName, loginRadiusEnv, publication }: AvatarBlockProps) => {
  const { avatarClickHandler, isLoggedIn, user, isMenuOpened }: AvatarHooks = useAvatar(isAuthFlowEnabled);

  const getUserBasicInfo = useCallback((userOnly: UserOnly): UserBasicInfo => {
    const { FirstName, LastName, Email } = userOnly;
    return {
      firstName: FirstName || '',
      lastName: LastName || '',
      email: Email[0].Value || '',
    } as UserBasicInfo;
  }, []);

  return (
    <button
      className={avatarStyles['avatar-container']}
      data-testid="avatar"
      onClick={() => avatarClickHandler()}
      aria-label={`User Avatar - ${isLoggedIn ? 'redirects to user profile' : 'redirects to sign in and sign up'}`}
    >
      <Avatar user={isLoggedIn && user ? getUserBasicInfo(user) : undefined} />
      {isAuthFlowEnabled && (
        <AuthModal loginRadiusEnv={loginRadiusEnv} publicationId={publication} publicationName={$publicationName} />
      )}
      {isAuthFlowEnabled && isLoggedIn && isMenuOpened && <AvatarDropdown />}
    </button>
  );
};

export default AvatarBlock;
