'use client';

import { MenuLink } from '@/ts';
import { useState } from 'react';
import { useHeaderStore } from '@/store/header/header-providers';
import AnalyticsTrack from '@/components/atoms/AnalyticsTrack/AnalyticsTrack';
import linkHelper from '@/helpers/analytics/linkHelper';
import { getFontOnlyForPublication } from '@/helpers/utils/fonts';
import { joinClassNames } from '@/helpers/utils/classNames';
import styles from './styles/SecondaryNavigation.module.scss';

export interface SecondaryNavigationHeaderProps {
  isNavCurtain?: boolean;
  items?: MenuLink[];
  isCurtainOpen?: boolean;
  publication?: string;
}

const SecondaryNavigationHeader: React.FC<SecondaryNavigationHeaderProps> = ({
  isNavCurtain = false,
  items = [],
  publication,
}) => {
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [startingX, setStartingX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [hasMovedDuringDrag, setHasMovedDuringDrag] = useState(false);

  const headerStore = useHeaderStore((store) => store);
  const {
    state: { moreCurtainOpen, burgerOpen, growthBookExperiment },
  } = headerStore;

  const handleMouseDown = (e: any) => {
    setIsGrabbing(true);
    setStartingX(e.pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
    setHasMovedDuringDrag(false);
  };

  const handleMouseUp = (e: any) => {
    setIsGrabbing(false);
    if (hasMovedDuringDrag) e.preventDefault();
  };

  const handleMouseMove = (e: any) => {
    e.preventDefault();
    if (!isGrabbing) return;
    const x = e.pageX - e.currentTarget.offsetLeft;
    const walk = x - startingX;
    e.currentTarget.scrollLeft = scrollLeft - walk;
    if (Math.abs(x - startingX) > 5) {
      setHasMovedDuringDrag(true);
    }
  };

  if (burgerOpen && growthBookExperiment) return <></>;
  return (
    <nav
      className={`${styles['secondary-nav-container']} ${
        (moreCurtainOpen || burgerOpen) && isNavCurtain ? styles['curtain-open'] : ''
      }`}
      id="secondary-navigation"
      data-testid="secondary-navigation"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={() => setIsGrabbing(false)}
      onMouseMove={handleMouseMove}
    >
      <div className={styles['inline-container']}>
        {items.map((item, index) => (
          <div className={`${styles['item-container']}`} key={index}>
            <AnalyticsTrack
              analyticsData={linkHelper('SECONDARY_HEADER_NAVIGATION', item.url || '', {
                componentTitle: item.title,
                componentID: '',
                componentText: item.title,
              })}
            >
              <a
                className={joinClassNames(
                  styles.item,
                  '.tab-focus',
                  isGrabbing && 'grabbing',
                  publication === 'mirror' && getFontOnlyForPublication(publication)
                )}
                key={index}
                href={item.url}
                data-tmdatatrack="nav"
                data-tmdatatrack-name={item.title}
              >
                {item.title}
              </a>
            </AnalyticsTrack>
          </div>
        ))}
      </div>
    </nav>
  );
};

export default SecondaryNavigationHeader;
