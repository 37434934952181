'use client';

import type { GPTConfigProps } from '@/commercial/types';
import useAdsConfig from '@/store/adsConfig/adsConfig';
import { getGPTConfigById } from '@/store/adsConfig/selectors';
import { useHeaderStore } from '@/store/header/header-providers';
import CommercialBox from '@/components/molecules/CommercialBox/CommercialBox';
import styles from './styles/Header.module.scss';

interface CommercialBlockProps extends React.PropsWithChildren {
  disableCommercial: boolean;
  dataTestId?: string;
}

const CommercialBlock = ({ disableCommercial, dataTestId }: CommercialBlockProps) => {
  const headerStore = useHeaderStore((store) => store);
  const {
    state: { growthBookExperiment },
  } = headerStore;
  const adConfigStore = useAdsConfig();
  const slotConfig: GPTConfigProps | null = getGPTConfigById('div-gpt-ad-ad-web-strip')(adConfigStore);
  const hasMastheadWebStripEnabled = slotConfig?.enabled === true;

  if (disableCommercial === true || !hasMastheadWebStripEnabled) return <></>;
  return (
    <div
      className={`${styles['mastheadwebstrip-container']} ${growthBookExperiment ? styles['is-experiment'] : ''}`}
      {...(dataTestId ? { 'data-testid': dataTestId } : {})}
    >
      <CommercialBox position={2} location="header" />
    </div>
  );
};

export default CommercialBlock;
