'use client';

import { useEffect } from 'react';
import { useHeaderStore } from '@/store/header/header-providers';
import clientWidth from '@/helpers/utils/clientWidthNative';

const ScreenSizeDetect = () => {
  const headerStore = useHeaderStore((store) => store);
  const {
    actions: { setScreenSize },
  } = headerStore;

  useEffect(() => {
    setScreenSize(clientWidth());
    const handleResize = () => {
      setScreenSize(clientWidth());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [setScreenSize]);

  return <></>;
};

export default ScreenSizeDetect;
