import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
// import { initialAnalyticsDataState } from '@/store/assets/initialStates';
// import type { AnalyticsData, any } from '@/ts';

interface HeaderState {
  burgerOpen: boolean;
  growthBookExperiment: boolean;
  moreCurtainOpen: boolean;
  screenSize: string;
}

export interface HeaderStore {
  state: HeaderState;
  actions: {
    checkScreenSize: (checkSize: string) => boolean;
    setMoreCurtain: (open: boolean) => void;
    setBurger: (open: boolean) => void;
    setExperiment: (check: boolean) => void;
    setScreenSize: (screenSize: string) => void;
  };
}

const initialHeaderState = {
  screenSize: 'mobile',
  burgerOpen: false,
  moreCurtainOpen: false,
};

export const initHeaderStore = (): any => ({
  ...initialHeaderState,
});

export const createHeaderStore = (initState: any) =>
  create<HeaderStore>()(
    devtools(
      (set, get) => ({
        state: { ...initState },
        actions: {
          checkScreenSize: (checkSize: string) => {
            const {
              state: { screenSize },
            } = get();

            return screenSize === checkSize;
          },

          setBurger: (open) => {
            set((currentStore) => ({
              state: {
                ...currentStore.state,
                burgerOpen: open,
              },
            }));
          },
          setExperiment: (check) => {
            set((currentStore) => ({
              state: {
                ...currentStore.state,
                growthBookExperiment: check,
              },
            }));
          },
          setMoreCurtain: (open) => {
            set((currentStore) => ({
              state: {
                ...currentStore.state,
                moreCurtainOpen: open,
              },
            }));
          },
          setScreenSize: (screenSize: string) => {
            set((currentStore) => ({
              state: {
                ...currentStore.state,
                screenSize,
              },
            }));
          },
        },
      }),
      {
        name: 'header',
      }
    )
  );

export default createHeaderStore;
