'use client';

import useGptSlots from '@/store/gptSlots/gptSlots';
import useCustomAdFormats from '@/store/customAd/customAdFormats';
import { hasSkyscraperAdResponse } from '@/store/gptSlots/selectors';
import { hasSkinAd } from '@/store/customAd/selectors';
import styles from './styles/Header.module.scss';

interface HeaderWrapperProps extends React.PropsWithChildren {
  $backgroundColor: string;
  dataTestId?: string;
  extendedHeaderEnabled: boolean;
  navigationEnabled: boolean;
  navigatorOnly: boolean;
}

const HeaderWrapper = ({
  children,
  $backgroundColor,
  dataTestId = 'header',
  navigationEnabled,
  navigatorOnly,
  extendedHeaderEnabled,
}: HeaderWrapperProps) => {
  const customAdStore = useCustomAdFormats(); // Re-renders
  const gptSlotsStore = useGptSlots();

  const headerClassNames = `${styles.header}
  ${styles[`header-${$backgroundColor}`]}
  ${navigationEnabled ? styles['with-burger'] : ''}
  ${navigatorOnly ? styles['navigator-only-header'] : ''}
  ${
    !extendedHeaderEnabled || hasSkinAd(customAdStore) || hasSkyscraperAdResponse(gptSlotsStore)
      ? ''
      : styles['extended-header']
  }
  tab-focus-all-elems
  `;

  return (
    <header data-testid={dataTestId} className={headerClassNames}>
      {children}
    </header>
  );
};

export default HeaderWrapper;
