'use client';

import useExperiment from '@/helpers/growthbook/useExperiment';
import { useEffect } from 'react';

export default function ID3599({ feature }: { feature: string }) {
  const experimentVariant = useExperiment({ feature });

  useEffect(() => {
    if (experimentVariant) {
      switch (experimentVariant) {
        case '1':
          console.log(`3599 v1`);
          break;
        default:
          console.log(`3599 control`);
          break;
      }
    }
  }, [experimentVariant]);

  return experimentVariant ? (
    <>
      {
        // experiment code here
        // in case of UI based experiment place switch case can be place here also
      }
    </>
  ) : (
    <></>
  );
}
