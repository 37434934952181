'use client';

import { useAnalyticStore } from '@/store/analytics/analytics-provider';
import { useHeaderStore } from '@/store/header/header-providers';
import styles from './style/Burger.module.scss';

const CloseIcon = (
  <svg
    data-testid="close-icon"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L11 11M1 11L11 1"
      stroke="var(--color-com-header-primary-nav-hamburger-icon)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Burger = () => {
  // const [isCloseBtn, setIsCloseBtn] = useState(Boolean(isOpen));
  const headerStore = useHeaderStore((store) => store);
  const {
    state: { burgerOpen },
    actions: { setBurger },
  } = headerStore;
  const {
    actions: { clickEvent },
    state: {
      client: { analytics },
    },
  } = useAnalyticStore((store) => store);

  const toggle = () => {
    setBurger(!burgerOpen);
  };

  const clickHandler = () => {
    const event = burgerOpen ? 'BURGER_OPEN' : 'BURGER_CLOSE';

    const componentTitle = burgerOpen ? 'Open' : 'Close';

    clickEvent(`HEADER_NAV:${event}`, { componentTitle, linkURL: window.location.href, ...analytics });

    toggle();
  };

  return (
    <button
      className={`${styles['burger-wrapper']} .tab-focus-internal`}
      onClick={clickHandler}
      tabIndex={0}
      role="button"
      aria-label="Hamburger menu"
      aria-expanded={burgerOpen}
      aria-controls="mobile-nav"
      aria-haspopup="menu"
    >
      <div
        className={burgerOpen ? styles['burger-close-btn'] : styles['burger-menu']}
        data-testid={burgerOpen ? 'close-burger' : 'burger'}
      >
        {burgerOpen && CloseIcon}
      </div>
    </button>
  );
};

Burger.displayName = 'Burger';

export default Burger;
