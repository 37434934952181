'use client';

import { useParams } from 'next/navigation';

import AdsConfigStoreInitializer from '@/components/services/AdsConfigStoreInitializer';
import CommercialBoxAggStoreInitializer from '@/components/services/CommercialBoxAggStoreInitializer';
import CommercialBoxesConfigStoreInitializer from '@/components/services/CommercialBoxesConfigStoreInitializer';
import CommercialFeatureControlStoreInitializer from '@/components/services/CommercialFeatureControlStoreInitializer';
import CookieStoreInitializer from '@/components/services/CookieStoreInitializer';
import CountryCodeInitializer from '@/components/services/CountryCodeInitializer';
import GPTFeatureControlInitializer from '@/components/services/GPTFeatureControlInitializer';
import HydraStoreInitializer from '@/components/services/HydraStoreInitializer';
import TakeoverDetectionInitializer from '@/components/services/TakeoverDetectionInitializer';

import LoggerCookieService from '@/components/atoms/LggerCookieService/LoggerCookieService';
import ConsentDataService from '@/components/atoms/ConsentDataService/ConsentDataService';

import useCookie from '@/store/cookie/cookie';

import getAdsConfig from '@/helpers/commercial/adsConfig';
import getHydraData from '@/helpers/commercial/getHydraData';
import getCommercialBoxesConfig from '@/helpers/commercial/getCommercialBoxesConfig';
import getBoxAggregationData from '@/helpers/commercial/getBoxAggregationData';
import getTakeoverDetectionData from '@/helpers/commercial/takeoverDetection';

import checkProduction from '@/helpers/utils/checkProduction';

interface IntializersProps {
  configData: any;
}

const Intializers = ({ configData }: IntializersProps) => {
  const {
    commercialConfig,
    countryCode,
    disableCommercial,
    environment,
    gptLazyLoadingEnabled,
    isolateCommercialFeature,
    publication,
  } = configData;

  const params = useParams();

  const adsConfigData = getAdsConfig(commercialConfig, params);
  const takeoverDetectionData = getTakeoverDetectionData(commercialConfig);
  // const lightningLoadData = getLightningLoadData(commercialConfig);
  const hydra = getHydraData(commercialConfig);
  const commercialBoxesConfig = getCommercialBoxesConfig(commercialConfig);
  const boxAggregationData = getBoxAggregationData(commercialConfig);

  const isProduction = checkProduction();

  const cookieData = { pubCookie: publication, envCookie: environment };
  useCookie.getState().actions.addCookieDataToServerStore(cookieData);

  const commercialFeatureControlData = {
    isolateCommercialFeature,
    disableCommercial,
  };
  const gptFeatureControlData = {
    gptLazyLoadingEnabled,
  };

  return (
    <>
      <LoggerCookieService />
      <ConsentDataService />
      <AdsConfigStoreInitializer adsConfigData={adsConfigData} />
      <CommercialBoxesConfigStoreInitializer commercialBoxesConfig={commercialBoxesConfig} />
      <CommercialBoxAggStoreInitializer boxAggregationData={boxAggregationData} />
      <HydraStoreInitializer hydraData={hydra} />
      <TakeoverDetectionInitializer takeoverDetectionData={takeoverDetectionData} />
      <CountryCodeInitializer countryCode={countryCode} />
      <GPTFeatureControlInitializer gptFeatureControlData={gptFeatureControlData} />
      {!isProduction && (isolateCommercialFeature || disableCommercial) && (
        <CommercialFeatureControlStoreInitializer featureControlData={commercialFeatureControlData} />
      )}
      <CookieStoreInitializer cookieData={cookieData} />
    </>
  );
};

export default Intializers;
