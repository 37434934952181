import { BlinkerProps } from '@/ts/interfaces/blinker';
import { mapClassNames } from '@/helpers/utils/classNames';
import styles from './styles/Blinker.module.scss';

const Blinker: React.FC<BlinkerProps> = ({ color = 'default', blinkCount = 'infinite' }) => (
  <span
    className={mapClassNames(styles, 'blinker', color)}
    style={{ animationIterationCount: blinkCount }}
    data-testid="blinker"
  ></span>
);

export default Blinker;
