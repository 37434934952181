'use client';

import { type ReactNode, createContext, useRef, useContext } from 'react';
import { useStore } from 'zustand';
import { HeaderStore, createHeaderStore, initHeaderStore } from './header';

export type HeaderStoreApi = ReturnType<typeof createHeaderStore>;

export interface HeaderStoreProviderProps {
  children: ReactNode;
}

export const HeaderStoreContext = createContext<HeaderStoreApi | undefined>(undefined);

export const HeaderStoreProvider = ({ children }: HeaderStoreProviderProps) => {
  const storeRef = useRef<HeaderStoreApi>();

  if (!storeRef.current) storeRef.current = createHeaderStore(initHeaderStore());

  return <HeaderStoreContext.Provider value={storeRef.current}>{children}</HeaderStoreContext.Provider>;
};

export const useHeaderStore = <T,>(selector: (store: HeaderStore) => T): T => {
  const headerStoreContext = useContext(HeaderStoreContext);

  if (!headerStoreContext) throw new Error('useHeaderStore must be used within HeaderStoreProvider');

  return useStore(headerStoreContext, selector);
};
