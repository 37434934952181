'use client';

// import { useMediaQuery } from 'react-responsive';
import { BREAKPOINTS } from '@/theme';
import type { ClientWidths } from '@/ts';

/** A client-only function. Returns a string describing the width of the client browser window
 * You can't use clientWidth to control which elements are included in server-rendered markup.
 * It should only be used to control behaviour.
 * You should useEffect when in use
 */

const useMediaQuery = (query: string) => {
  const mediaQuery = window.matchMedia(query);
  return mediaQuery.matches;
};

export default (): ClientWidths => {
  const medium = useMediaQuery(`(min-width: ${BREAKPOINTS.m}px) and (max-width: ${BREAKPOINTS.l - 1}px)`);
  const large = useMediaQuery(`(min-width: ${BREAKPOINTS.l}px) and (max-width: ${BREAKPOINTS.xl - 1}px)`);
  const xlarge = useMediaQuery(`(min-width: ${BREAKPOINTS.xl}px)`);
  if (xlarge) return 'xlarge';
  if (large) return 'large';
  if (medium) return 'medium';
  return 'small';
};
