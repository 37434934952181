'use client';

import { useRef } from 'react';
import type { CommercialBoxesAggregationConfig } from '@/ts';
import useCommercialBoxAggregationStore from '@/store/commercialBoxAggregation/commercialBoxAggregation';

function CommercialBoxAggStoreInitializer({
  boxAggregationData,
}: {
  boxAggregationData: CommercialBoxesAggregationConfig;
}) {
  const initialized = useRef(false);
  if (!initialized.current) {
    useCommercialBoxAggregationStore.getState().actions.addCommercialBoxAggConfigToServerStore(boxAggregationData);
    initialized.current = true;
  }
  return null;
}

export default CommercialBoxAggStoreInitializer;
