'use client';

import { getFontOnlyForPublication } from '@/helpers/utils/fonts';
import { joinClassNames } from '@/helpers/utils/classNames';
import ArrowIcon from '@/components/atoms/Icons/ArrrowHead';
import { useHeaderStore } from '@/store/header/header-providers';
import styles from './styles/NavigationItem.module.scss';

const NavigationItem = ({ publication }: { publication: string }) => {
  const headerStore = useHeaderStore((store) => store);
  const {
    state,
    actions: { setMoreCurtain },
  } = headerStore;
  const { moreCurtainOpen } = state;

  const toggle = (event: any) => {
    event.preventDefault();
    setMoreCurtain(!moreCurtainOpen);
  };

  const handleMouseEnterMore = () => {
    setMoreCurtain(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      toggle(event);
    }
  };

  return (
    <li className={`${styles['nav-item-wrapper']} ${moreCurtainOpen ? styles.active : ''}`}>
      <button
        className={joinClassNames(
          styles['nav-link'],
          publication === 'mirror' && getFontOnlyForPublication(publication),
          moreCurtainOpen ? styles.active : ''
        )}
        onMouseEnter={handleMouseEnterMore}
        onClick={toggle}
        onKeyDown={handleKeyDown}
        data-tmdatatrack-name="More"
        data-tmdatatrack="nav"
        aria-haspopup="true"
        aria-expanded={moreCurtainOpen}
        aria-controls="MoreCurtain"
      >
        More{' '}
        <span className={styles['icon-wrapper']}>
          <ArrowIcon isUp={moreCurtainOpen} color="currentColor" />
        </span>
      </button>
    </li>
  );
};

export default NavigationItem;
