'use client';

import ID3599 from '@/components/molecules/Experiments/ID3599';
import { getSearchParam } from '@/helpers/utils/dom/locationHelpers';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { usePathname } from 'next/navigation';
import { ReactNode, useEffect, useMemo, useState } from 'react';

let growthbook: GrowthBook;

export default function GrowthBookService({
  children,
  publication,
  growthbookConfig,
}: {
  children: ReactNode;
  publication: any;
  growthbookConfig: any;
}) {
  // Refresh features and targeting attributes on navigation
  const pathname = usePathname();
  const [mounted, setMounted] = useState<boolean>(false);
  const paramCheck = getSearchParam('growthbookDisabled');
  const growthbookDisabled = paramCheck === 'true';

  const availableExperiments: { [key: string]: any } = {
    'nav-test': ID3599,
  };

  const setFelizUserId = () => {
    growthbook.updateAttributes({
      id: window.feliz.getUserId(),
    });
    window.removeEventListener('FELIZ', setFelizUserId);
  };
  growthbook = useMemo(
    () =>
      new GrowthBook({
        apiHost: growthbookConfig.apiHost,
        clientKey: growthbookConfig.clientKey,
        decryptionKey: growthbookConfig.decryptionKey,
        enableDevMode: true,
        trackingCallback: (experiment, result) => {
          const pushEvent = () => {
            window.feliz.event('optimise-experiment', {
              id: `${experiment.key}.${result.key}`,
            });
            window.removeEventListener('FELIZ', pushEvent);
          };

          if (window.feliz) {
            pushEvent();
          } else {
            window.addEventListener('FELIZ', pushEvent);
          }
        },
      }),
    []
  );

  useEffect(() => {
    // Start downloading feature definitions
    growthbook
      .init({
        streaming: false,
      })
      .then(() => {
        setMounted(true);
      });
    window.addEventListener('FELIZ', setFelizUserId);
    growthbook.setAttributes({
      id: window?.feliz?.getUserId(),
      url: pathname,
      publication,
    });
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      {children}
      {mounted && (
        <>
          {!growthbookDisabled &&
            Object.entries(availableExperiments).map(([key, Component]) => <Component key={key} feature={key} />)}
        </>
      )}
    </GrowthBookProvider>
  );
}
