'use client';

import { useEffect, useState } from 'react';
import TextLink from '@/components/atoms/TextLink/TextLink';
import Plus from '@/components/atoms/Icons/Plus';
import Minus from '@/components/atoms/Icons/Minus';
import NavArrowRight from '@/components/atoms/Icons/NavArrowRight';
import { MenuLink } from '@/ts';
import AnalyticsTrack from '@/components/atoms/AnalyticsTrack/AnalyticsTrack';
import linkHelper from '@/helpers/analytics/linkHelper';
import { useAnalyticStore } from '@/store/analytics/analytics-provider';
import styles from './styles/PrimaryMenuHeading.module.scss';

type PrimaryMenuHeadingProps = {
  item: MenuLink;
  itemKey?: number;
};

const iconColour = 'var(--color-sem-icon-primary-default';

export const getHref = (item: MenuLink) => {
  const isTopic = item.linkBehaviour === 'topic';
  const isTopic1 = item.linkBehaviour === 'topic1';
  if (isTopic) {
    return `${item.topicPatternInURI}/${item.topicRelation}`;
  }
  if (isTopic1) {
    return '#';
  }
  return item.url;
};

const PrimaryMenuHeading: React.FC<PrimaryMenuHeadingProps> = ({ item, itemKey = undefined }) => {
  const expandable = item.relation;
  const [expanded, setExpanded] = useState(false);
  const [isMounted, setIsMounted] = useState(Boolean(false));
  const menuItem = item.title;
  useEffect(() => {
    setIsMounted(true);
  }, [isMounted]);

  const {
    actions: { clickEvent },
    state: {
      client: { analytics },
    },
  } = useAnalyticStore((store) => store);

  const toggle = (url: string | undefined) => {
    setExpanded((prevState) => !prevState);

    if (expanded) {
      clickEvent('HEADER_NAV:LINK_DROPDOWN_CLOSE', {
        linkURL: url,
        ...analytics,
        componentTitle: menuItem,
        componentID: '',
        componentText: '',
      });
    } else {
      clickEvent('HEADER_NAV:LINK_DROPDOWN_OPEN', {
        linkURL: url,
        ...analytics,
        componentTitle: menuItem,
        componentID: '',
        componentText: '',
      });
    }
  };

  const expandButton = (
    <button
      className={styles['sub-menu-toggle']}
      role="button"
      type="button"
      aria-label={`expands submenu ${item.title}`}
      aria-haspopup={expandable ? 'true' : 'false'}
      aria-expanded={expanded}
      onClick={() => toggle(item?.url)}
    >
      {expanded ? <Minus color={iconColour} /> : <Plus color={iconColour} />}
    </button>
  );

  return (
    <li key={itemKey} role="menuitem">
      <div className={styles.heading}>
        <AnalyticsTrack
          analyticsData={linkHelper('HEADER_NAV:NAV_LINK', item.url || '', {
            componentTitle: item.title,
            componentID: '',
            componentText: 'menu item',
          })}
        >
          <TextLink href={getHref(item) as string} data-tmdatatrack-name={item.title} data-tmdatatrack="nav">
            {item.title}
            <NavArrowRight title={`redirects ${item.title}`} color={iconColour} />
          </TextLink>
        </AnalyticsTrack>
        {expandable && expandButton}
      </div>
      {expandable && (!isMounted || expanded) && (
        <ul className={styles['sub-menu']} role="menu" aria-label="Submenu" aria-hidden="true">
          {(expandable as any).map((innerLink: MenuLink, innerIndex: number) => (
            <li key={innerIndex} role="menuitem">
              <AnalyticsTrack
                analyticsData={linkHelper('HEADER_NAV:NAV_LINK', innerLink.url || '', {
                  componentTitle: innerLink.title,
                  componentID: '',
                  componentText: 'menu sub-item',
                })}
              >
                <TextLink
                  href={getHref(innerLink) as string}
                  data-tmdatatrack-name={innerLink.title}
                  data-tmdatatrack="nav"
                  isSubMenuItem
                >
                  {innerLink.title}
                </TextLink>
              </AnalyticsTrack>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default PrimaryMenuHeading;
