'use client';

import { useEffect } from 'react';
import { useHeaderStore } from '@/store/header/header-providers';
import { useAnalyticStore } from '@/store/analytics/analytics-provider';
import { MenuLink } from '@/ts';
import Close from '@/components/atoms/Icons/Close';
import SocialFollowBar from '@/components/molecules/SocialFollowBar/SocialFollowBar';
import { SocialPlatformData } from '@/ts/interfaces/socialPlatforms';
import ArrowRightCurtain from '@/components/atoms/Icons/ArrowRightCurtain';
import AnalyticsTrack from '@/components/atoms/AnalyticsTrack/AnalyticsTrack';
import linkHelper from '@/helpers/analytics/linkHelper';
import { preventFocus, resetFocusing } from '@/helpers/navCurtain/curtainHelpers';
import { Territory } from '@/ts/interfaces/territorySwitcher';
import font, { getFontOnlyForPublication } from '@/helpers/utils/fonts';
import { joinClassNames } from '@/helpers/utils/classNames';
import styles from './styles/MoreCurtain.module.scss';
import ErrorBoundaryFallback from '../ErrorBoundaryFallback/ErrorBoundaryFallback';
import TerritorySwitcher from '../TerritorySwitcher/TerritorySwitcher';

export interface MoreCurtainProps {
  // onClick?: () => void;
  // onMouseLeave?: () => void;
  // isOpen?: boolean;
  items?: { [key: string]: MenuLink[] };
  breakpoint?: string;
  publication: string;
  publicationName: string;
  socialPlatforms: SocialPlatformData[];
  territorySwitcher?: {
    territorySwitcherEnabled?: boolean;
    territories?: Territory[];
  };
  // trackMoreOpen?: () => void;
  // trackMoreClose?: () => void;
  secondaryItems?: MenuLink[];
  isExperimentOn?: boolean;
}

const tabFocusing = (() => {
  const mainElements: Element[] = [];
  const mainValues: any[] = [];
  const footerElements: Element[] = [];
  const footerValues: any[] = [];

  return {
    prevent: () => {
      preventFocus(mainElements, mainValues, '#main-content');
      preventFocus(footerElements, footerValues, 'footer');
    },
    reset: () => {
      resetFocusing(mainElements, mainValues);
      resetFocusing(footerElements, footerValues);
    },
  };
})();

const MoreCurtain: React.FC<MoreCurtainProps> = ({
  items = {},
  territorySwitcher = {},
  publication,
  publicationName,
  socialPlatforms,
  secondaryItems = [],
  isExperimentOn,
}) => {
  const headerStore = useHeaderStore((store) => store);
  const {
    state,
    actions: { setMoreCurtain },
  } = headerStore;
  const { moreCurtainOpen } = state;

  const {
    actions: { clickEvent },
    state: {
      client: { analytics },
    },
  } = useAnalyticStore((store) => store);

  const toggle = (event: any) => {
    event.preventDefault();
    setMoreCurtain(!moreCurtainOpen);
  };

  useEffect(() => {
    if (moreCurtainOpen) {
      clickEvent('HEADER_NAV:MORE_OPEN', { componentTitle: 'More', linkURL: window.location.href, ...analytics });
      tabFocusing.prevent();
    } else {
      tabFocusing.reset();
      clickEvent('HEADER_NAV:MORE_CLOSE', { componentTitle: 'Close', linkURL: window.location.href, ...analytics });
    }
  }, [moreCurtainOpen, analytics, clickEvent]);

  const onMouseLeave = () => {
    setMoreCurtain(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') toggle(event);
  };

  const InnerClose = (
    <button
      className={joinClassNames(
        styles['inner-close'],
        publication === 'mirror' ? getFontOnlyForPublication(publication) : font(publication)
      )}
      tabIndex={0}
      role="button"
      data-testid="nav-curtain-close"
      onClick={toggle}
      onKeyDown={handleKeyDown}
    >
      CLOSE <Close color="currentColor" />
    </button>
  );

  if (!moreCurtainOpen) return null;
  return (
    <ErrorBoundaryFallback>
      <nav
        className={`${styles['desktop-curtain']}`}
        onMouseLeave={onMouseLeave}
        data-testid="more-curtain"
        id="MoreCurtain"
        role="menu"
      >
        <ul className={styles['column-list']} role="menu">
          {Object.keys(items).map((columnName, columnIndex) => (
            <li className={styles.column} key={columnIndex} role="menuitem" aria-haspopup="true" aria-expanded="false">
              {items[columnName as keyof typeof items].map((item) => (
                <>
                  <AnalyticsTrack
                    analyticsData={linkHelper('NAV_LINK', item.url || '', {
                      componentTitle: item.title,
                      componentID: '',
                      componentText: 'menu item',
                    })}
                  >
                    <a
                      className={joinClassNames(
                        styles['primary-link'],
                        publication === 'mirror' && getFontOnlyForPublication(publication)
                      )}
                      data-tmdatatrack-name={item.title}
                      data-tmdatatrack="nav"
                      href={item.url}
                    >
                      {item.title} <ArrowRightCurtain color="currentColor" />
                    </a>
                  </AnalyticsTrack>
                  {item.relation && (
                    <ul className={styles['sub-menu']} role="menu" aria-label="Submenu" aria-hidden="true">
                      {item.relation.map((subItem, subItemIndex) => (
                        <li key={subItemIndex} role="menuitem">
                          <AnalyticsTrack
                            analyticsData={linkHelper('SECONDARY_HEADER_NAVIGATION', subItem.url || '', {
                              componentTitle: subItem.title,
                              componentID: '',
                              componentText: 'menu sub-item',
                            })}
                          >
                            <a
                              className={joinClassNames(
                                styles['sub-link'],
                                publication === 'mirror' && getFontOnlyForPublication(publication)
                              )}
                              data-tmdatatrack-name={subItem.title}
                              data-tmdatatrack="nav"
                              href={subItem.url}
                            >
                              {subItem.title}
                            </a>
                          </AnalyticsTrack>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              ))}
            </li>
          ))}
        </ul>

        {isExperimentOn && (
          <ul className={styles['menu-grid']} data-testid="secondary-navigation">
            {secondaryItems.map((item, index) => (
              <li key={index} className={styles.column}>
                <AnalyticsTrack
                  analyticsData={linkHelper('SECONDARY_HEADER_NAVIGATION', item.url || '', {
                    componentTitle: item.title,
                    componentID: '',
                    componentText: item.title,
                  })}
                >
                  <a
                    className={styles['sub-link']}
                    key={index}
                    href={item.url}
                    data-tmdatatrack="nav"
                    data-tmdatatrack-name={item.title}
                  >
                    {item.title}
                  </a>
                </AnalyticsTrack>
              </li>
            ))}
          </ul>
        )}

        <div className={styles['bottom-container']}>
          <SocialFollowBar
            socialButtons={{
              socialPlatforms,
              designType: 'primary',
              hideLabel: true,
            }}
            hideHeaderText
            dataTmDataTrack="nav"
            publication={publication}
            publicationName={publicationName}
            location="curtain"
          />
          {territorySwitcher.territorySwitcherEnabled && territorySwitcher.territories && (
            <TerritorySwitcher territories={territorySwitcher.territories} />
          )}
          {InnerClose}
        </div>
      </nav>
    </ErrorBoundaryFallback>
  );
};

MoreCurtain.displayName = 'MoreCurtain';

export default MoreCurtain;
