'use client';

import { useHeaderStore } from '@/store/header/header-providers';
import styles from './styles/HideBlock.module.scss';

interface HideBlockProps extends React.PropsWithChildren {
  type: string;
  navigationEnabled?: boolean;
  dataTestId?: string;
}

const checkLogo = ({ burgerOpen }: any) => !burgerOpen;

const checkSocials = ({ burgerOpen, screenSize, navigationEnabled }: any): boolean =>
  !navigationEnabled || burgerOpen || screenSize === 'xlarge';

const HideBlock = ({ children, type, dataTestId, navigationEnabled = true }: HideBlockProps) => {
  const headerStore = useHeaderStore((store) => store);
  const { state } = headerStore;

  const checker = type === 'logo' ? checkLogo : checkSocials;

  const cssClass = `${checker({ ...state, navigationEnabled }) ? styles.hideblock : styles['hideblock-hidden']}`;

  const logoClass = type === 'logo' ? styles['hideblock-logo'] : styles['hideblock-socials'];

  return (
    <div data-testid={dataTestId} className={`${cssClass} ${logoClass}`}>
      {children}
    </div>
  );
};

export default HideBlock;
