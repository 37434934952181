'use client';

import { useEffect } from 'react';
import runExperiment from '@/helpers/growthbook/runExperiment';
import { useHeaderStore } from '@/store/header/header-providers';

const HeaderGrowthBook = ({ growthbookEnabled = false }: { growthbookEnabled?: boolean }) => {
  const headerStore = useHeaderStore((store) => store);
  const {
    actions: { setExperiment },
  } = headerStore;

  const isExperimentOn = growthbookEnabled ? runExperiment(/* { feature: 'id-9296' } */) === '1' : false;

  useEffect(() => {
    setExperiment(isExperimentOn);
  }, [setExperiment, isExperimentOn]);

  return <></>;
};

export default HeaderGrowthBook;
