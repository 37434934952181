import { Flags } from '../types/icons';

export interface TerritorySwitcherProps {
  territories: Territory[];
  layout?: 'row' | 'column';
}

export interface Territory {
  label: TerritoryLabel;
  url: string;
  active?: boolean;
}

export type TerritoryLabel = 'UK' | 'IRE' | 'US';

export const territoryIconMap: Record<TerritoryLabel, Flags> = {
  UK: 'uk',
  US: 'us',
  IRE: 'ireland',
};
